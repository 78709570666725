import Cookies from 'js-cookie';
import FetchServicesCustomErrors from './BaseFetch';

export const IsLoggedIn = async () => {
  try {
    const response = await FetchServicesCustomErrors({
      endpoint: `${import.meta.env.REACT_APP_API_SERVER}/api/v1/get-my-user-id`,
    });
    return !!response.user_id;
  } catch (error) {
    console.error('Error checking login status:', error);
    return false;
  }
};

export function GetSessionId() {
  return Cookies.get('session_id_front_only');
}

export function FetchAuthStatus(msgId: string) {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/auth/status${msgId ? `?msg_id=${msgId}` : ''}`;

  return FetchServicesCustomErrors({ endpoint });
}

export function FetchAuthOptions(dni: string) {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/auth/options/${dni}`;
  return FetchServicesCustomErrors({ endpoint });
}
